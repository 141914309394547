// Since this is a static website, the search functionality is all handled by just JavaScript filtering method

import React, { useState, useEffect } from "react";
import { graphql, Link, navigate } from "gatsby";
import Layout from "../layout";
import styled from "styled-components";
import ReactPlayer from "react-player";
import SEO from "../components/seo";
import { formatDate } from "../components/helpers";
import {
  navyBlue,
  mainRed,
  mainWhite,
  lightBlue,
  lightNavyBlue,
  mainBlue,
  aku,
  innerWidth,
  transHover,
  gotham,
  gothamBold,
  screen,
} from "../components/common/variables";
import Arrow from "../images/svg/right-arrow.svg";
import VideoModal from "../components/video-modal";
import {
  getVimeoId,
  reduceWords,
  toSlug,
} from "../components/common/functions";
import { FaSearch } from "react-icons/fa";

const Wrapper = styled.div`
  padding-bottom: 90px;
  padding-top: 124px;
  @media ${screen.small} {
    padding-top: 135px;
    padding-bottom: 150px;
  }
  @media ${screen.medium} {
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      font-size: 2.7rem;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .main-container {
    max-width: ${innerWidth};
    margin: 45px auto 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__form {
      position: relative;
      max-width: 400px;
      width: 100%;

      input {
        color: ${navyBlue};
        display: block;
        border: 0;
        border-radius: 0;
        font-family: ${gotham};
        font-size: 1.2rem;
        outline: none;
        width: 100%;
        border-bottom: 2px ${navyBlue} solid;
        padding: 5px;
        @media ${screen.small} {
          font-size: 1.4rem;
        }
        @media ${screen.xLarge} {
          padding: 8px;
        }

        ::placeholder {
          color: rgba(37, 61, 95, 0.6);
        }
      }

      .submit {
        cursor: pointer;
        color: ${navyBlue};
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: ${transHover};

        &:hover {
          color: ${mainRed};
        }
        svg {
          font-size: 1.8rem;
          @media ${screen.small} {
            font-size: 2rem;
          }
        }
      }
    }

    &__feedback {
      color: ${navyBlue};
      font-size: 1.1rem;
      font-family: ${gotham};
      margin-top: 25px;

      .searched-text {
        font-family: ${gothamBold};
      }
    }

    &__success-result {
      max-width: 1200px;
      margin-top: 60px;
      position: relative;
      @media ${screen.large} {
        margin-top: 85px;
      }

      .video-result {
        position: relative;
        padding-bottom: 30px;

        &__heading {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 1.8rem;
          margin-bottom: 20px;
        }

        &__list {
          .video-item {
            display: flex;
            flex-direction: column;
            margin-bottom: 55px;
            @media ${screen.small} {
              margin-bottom: 60px;
            }
            @media ${screen.medium} {
              margin-bottom: 10px;
              flex-direction: row;
            }
            @media ${screen.large} {
              margin-bottom: 20px;
            }

            &__img {
              cursor: pointer;
              .thumbnail {
                max-width: 100% !important;
                width: 100%;
                pointer-events: none;
                @media ${screen.medium} {
                  max-width: 470px !important;
                }
                @media ${screen.large} {
                  max-width: 470px !important;
                }
                @media ${screen.xLarge} {
                  max-width: 500px !important;
                }
                .react-player__preview {
                  background-size: cover !important;
                  max-width: 100% !important;
                  width: 100%;
                  height: 250px !important;
                  @media ${screen.small} {
                    height: 320px !important;
                  }
                  @media ${screen.medium} {
                    max-width: 470px !important;
                    height: 300px !important;
                  }
                  @media ${screen.large} {
                    max-width: 470px !important;
                    height: 300px !important;
                  }
                  @media ${screen.xLarge} {
                    max-width: 500px !important;
                    height: 300px !important;
                  }
                }

                .react-player__play-icon,
                .react-player__shadow {
                  display: none !important;
                }
              }
            }

            &__texts {
              margin-top: -100px;
              @media ${screen.small} {
                margin-top: -20px;
              }
              @media ${screen.medium} {
                margin-left: 45px;
                margin-top: 0;
              }

              .video-title {
                cursor: pointer;
                color: ${navyBlue};
                font-family: ${gothamBold};
                transition: ${transHover};
                max-width: 580px;
                font-size: 1.2rem;
                @media ${screen.small} {
                  font-size: 1.4rem;
                }
                @media ${screen.medium} {
                  max-width: inherit;
                }
                @media ${screen.withCursor} {
                  &:hover {
                    color: ${mainRed};
                  }
                }
              }

              .video-date {
                color: ${navyBlue};
                font-family: ${gotham};
                margin-top: 5px;
                @media ${screen.small} {
                  margin-top: 10px;
                }
              }

              .video-link {
                background: ${navyBlue};
                cursor: pointer;
                display: table;
                color: ${mainWhite};
                font-family: ${gotham};
                transition: ${transHover};
                font-size: 1rem;
                margin-top: 14px;
                padding: 11px 16px;
                @media ${screen.small} {
                  font-size: 1.1rem;
                  padding: 13px 20px;
                  margin-top: 20px;
                }
                @media ${screen.withCursor} {
                  &:hover {
                    background: ${mainRed};
                  }
                }
              }
            }
          }
        }

        &::after {
          content: "";
          width: 100%;
          height: 2px;
          background-image: linear-gradient(
            45deg,
            ${navyBlue},
            ${lightNavyBlue},
            ${mainBlue},
            ${lightBlue}
          );
          display: block;
          position: absolute;
          bottom: 0;
        }
      }

      .blog-result {
        position: relative;
        padding-bottom: 30px;
        margin-top: 45px;

        &__heading {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 1.8rem;
          margin-bottom: 20px;
        }

        &__list {
          .blog-item {
            margin-bottom: 60px;

            .blog-title {
              cursor: pointer;
              color: ${navyBlue};
              font-family: ${gothamBold};
              transition: ${transHover};
              font-size: 1.2rem;
              @media ${screen.small} {
                font-size: 1.4rem;
              }
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainRed};
                }
              }
            }

            .blog-date {
              color: ${navyBlue};
              font-family: ${gotham};
              margin-top: 10px;
            }

            .blog-description {
              color: ${navyBlue};
              font-family: ${gotham};
              margin-top: 10px;
              font-size: 1rem;
              @media ${screen.small} {
                font-size: 1.1rem;
              }
            }

            .blog-link {
              background: ${navyBlue};
              cursor: pointer;
              display: table;
              color: ${mainWhite};
              font-family: ${gotham};
              transition: ${transHover};
              font-size: 1rem;
              margin-top: 14px;
              padding: 11px 16px;
              @media ${screen.small} {
                font-size: 1.1rem;
                padding: 13px 20px;
                margin-top: 20px;
              }
              @media ${screen.withCursor} {
                &:hover {
                  background: ${mainRed};
                }
              }
            }
          }
        }

        &::after {
          content: "";
          width: 100%;
          height: 2px;
          background-image: linear-gradient(
            45deg,
            ${navyBlue},
            ${lightNavyBlue},
            ${mainBlue},
            ${lightBlue}
          );
          display: block;
          position: absolute;
          bottom: 0;
        }
      }

      .service-result {
        position: relative;
        padding-bottom: 30px;
        margin-top: 45px;

        &__heading {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 1.8rem;
          margin-bottom: 20px;
        }

        &__list {
          .service-item {
            margin-bottom: 60px;

            .service-title {
              cursor: pointer;
              color: ${navyBlue};
              font-family: ${gothamBold};
              transition: ${transHover};
              font-size: 1.2rem;
              @media ${screen.small} {
                font-size: 1.4rem;
              }
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainRed};
                }
              }
            }

            .service-date {
              color: ${navyBlue};
              font-family: ${gotham};
              margin-top: 10px;
            }

            .service-description {
              color: ${navyBlue};
              font-family: ${gotham};
              font-size: 1rem;
              margin-top: 10px;
              @media ${screen.small} {
                font-size: 1.1rem;
              }
            }

            .service-link {
              background: ${navyBlue};
              cursor: pointer;
              display: table;
              color: ${mainWhite};
              font-family: ${gotham};
              transition: ${transHover};
              font-size: 1rem;
              margin-top: 14px;
              padding: 11px 16px;
              @media ${screen.small} {
                font-size: 1.1rem;
                padding: 13px 20px;
                margin-top: 20px;
              }
              @media ${screen.withCursor} {
                &:hover {
                  background: ${mainRed};
                }
              }
            }
          }
        }
        &::after {
          content: "";
          width: 100%;
          height: 2px;
          background-image: linear-gradient(
            45deg,
            ${navyBlue},
            ${lightNavyBlue},
            ${mainBlue},
            ${lightBlue}
          );
          display: block;
          position: absolute;
          bottom: 0;
        }
      }

      .sector-result {
        position: relative;
        padding-bottom: 30px;
        margin-top: 45px;

        &__heading {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 1.8rem;
          margin-bottom: 20px;
        }

        &__list {
          .sector-item {
            margin-bottom: 60px;

            .sector-title {
              cursor: pointer;
              color: ${navyBlue};
              font-family: ${gothamBold};
              transition: ${transHover};
              font-size: 1.2rem;
              @media ${screen.small} {
                font-size: 1.4rem;
              }
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainRed};
                }
              }
            }

            .sector-date {
              color: ${navyBlue};
              font-family: ${gotham};
              margin-top: 10px;
            }

            .sector-description {
              color: ${navyBlue};
              font-family: ${gotham};
              font-size: 1rem;
              margin-top: 10px;
              @media ${screen.small} {
                font-size: 1.1rem;
              }
            }

            .sector-link {
              background: ${navyBlue};
              cursor: pointer;
              display: table;
              color: ${mainWhite};
              font-family: ${gotham};
              transition: ${transHover};
              font-size: 1rem;
              margin-top: 14px;
              padding: 11px 16px;
              @media ${screen.small} {
                font-size: 1.1rem;
                padding: 13px 20px;
                margin-top: 20px;
              }
              @media ${screen.withCursor} {
                &:hover {
                  background: ${mainRed};
                }
              }
            }
          }
        }

        &::after {
          content: "";
          width: 100%;
          height: 2px;
          background-image: linear-gradient(
            45deg,
            ${navyBlue},
            ${lightNavyBlue},
            ${mainBlue},
            ${lightBlue}
          );
          display: block;
          position: absolute;
          bottom: 0;
        }
      }

      &:last-child {
        &::after {
          content: "";
          background: ${mainWhite};
          height: 15px;
          display: block;
          width: 100%;
          bottom: -5px;
          position: absolute;
        }
      }
    }

    &__no-result {
      margin-top: 70px;
      @media ${screen.small} {
        margin-top: 100px;
      }
      @media ${screen.large} {
        margin-top: 120px;
      }

      .error-feedback {
        color: ${navyBlue};
        font-family: ${gothamBold};
        font-size: 1.3rem;
        @media ${screen.small} {
          font-size: 1.6rem;
        }
      }
      .home-link {
        background: ${navyBlue};
        cursor: pointer;
        display: table;
        color: ${mainWhite};
        font-family: ${gotham};
        transition: ${transHover};
        font-size: 1rem;
        margin-top: 20px;
        padding: 11px 16px;
        @media ${screen.small} {
          font-size: 1.1rem;
          padding: 13px 20px;
          margin-top: 30px;
        }
        @media ${screen.withCursor} {
          &:hover {
            background: ${mainRed};
          }
        }
      }
    }
  }
`;

const SearchPage = (props) => {
  // user search term state
  const [searchTerm, setSearchTerm] = useState(
    props.location.state ? props.location.state.term : ""
  );

  // default search result empty arrays
  const [searchResult, setSearchResult] = useState({
    videos: [],
    blogs: [],
    sectors: [],
    services: [],
  });

  // user search term to be displayed on the page
  const [searchedText, setSearchedText] = useState(
    props.location.state ? props.location.state.term : ""
  );

  // basic hack to trigger the video modal component to watch location hash changes
  const [triggerLightboxComponent, setTriggerLightboxComponent] = useState(
    false
  );

  const filteredBlogs = props.data.blogs.edges.filter(
    // filter one specific blog due of development
    (blog) => `Prismic__Blog__XVDg4REAACIAgtME` !== blog.node.id
  );

  useEffect(() => {
    if (searchTerm.length > 2) {
      const videoResult = props.data.videos.edges.filter((video) => {
        return video.node.data.title.text
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

      const blogResult = filteredBlogs.filter((blog) => {
        return blog.node.data.title.text
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

      const sectorResult = props.data.sectors.edges.filter((sector) => {
        return sector.node.data.title.text
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

      const infographics = props.data.infographics;
      const photography = props.data.photography;
      const design = props.data.design;

      // manually added Photography, Infographics and Design service to appear on search result
      const totalServices = [
        ...props.data.services.edges,
        {
          node: {
            id: design.id,
            slugs: [toSlug(design.data.page.text)],
            first_publication_date: "",
            data: {
              body: [
                {
                  primary: {
                    description: {
                      text: design.data.body[0].primary.description.text,
                    },
                  },
                },
              ],
              title: { text: design.data.page.text },
            },
          },
        },
        {
          node: {
            id: photography.id,
            slugs: [toSlug(photography.data.page.text)],
            first_publication_date: "",
            data: {
              body: [
                {
                  primary: {
                    description: {
                      text: photography.data.body[0].primary.description.text,
                    },
                  },
                },
              ],
              title: { text: photography.data.page.text },
            },
          },
        },
        {
          node: {
            id: infographics.id,
            slugs: [toSlug(infographics.data.page.text)],
            first_publication_date: "",
            data: {
              body: [
                {
                  primary: {
                    description: {
                      text: infographics.data.body[0].primary.description.text,
                    },
                  },
                },
              ],
              title: { text: infographics.data.page.text },
            },
          },
        },
      ];

      // service that matched that user input
      const matchedServices = totalServices.filter((service) => {
        return service.node.data.title.text
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

      // final service object result that will display
      const serviceResult = matchedServices.map((service) => {
        return {
          id: service.node.id,
          title: service.node.data.title.text,
          description: service.node.data.body[0].primary.description.text,
          slug: toSlug(service.node.data.title.text),
        };
      });

      setSearchResult({
        videos: videoResult,
        blogs: blogResult,
        sectors: sectorResult,
        services: serviceResult,
      });
    }
  }, []);

  const goSearch = (e) => {
    e.preventDefault();

    setSearchedText(searchTerm);

    if (searchTerm.length < 3) {
      setSearchResult({
        videos: [],
        blogs: [],
        sectors: [],
        services: [],
      });
    } else {
      const videoResult = props.data.videos.edges.filter((video) => {
        return video.node.data.title.text
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

      const blogResult = filteredBlogs.filter((blog) => {
        return blog.node.data.title.text
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

      const sectorResult = props.data.sectors.edges.filter((sector) => {
        return sector.node.data.title.text
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

      const infographics = props.data.infographics;
      const photography = props.data.photography;
      const design = props.data.design;

      // manually added Photography, Infographics and Design service to appear on search result
      const totalServices = [
        ...props.data.services.edges,
        {
          node: {
            id: design.id,
            slugs: [toSlug(design.data.page.text)],
            first_publication_date: "",
            data: {
              body: [
                {
                  primary: {
                    description: {
                      text: design.data.body[0].primary.description.text,
                    },
                  },
                },
              ],
              title: { text: design.data.page.text },
            },
          },
        },
        {
          node: {
            id: photography.id,
            slugs: [toSlug(photography.data.page.text)],
            first_publication_date: "",
            data: {
              body: [
                {
                  primary: {
                    description: {
                      text: photography.data.body[0].primary.description.text,
                    },
                  },
                },
              ],
              title: { text: photography.data.page.text },
            },
          },
        },
        {
          node: {
            id: infographics.id,
            slugs: [toSlug(infographics.data.page.text)],
            first_publication_date: "",
            data: {
              body: [
                {
                  primary: {
                    description: {
                      text: infographics.data.body[0].primary.description.text,
                    },
                  },
                },
              ],
              title: { text: infographics.data.page.text },
            },
          },
        },
      ];

      // service that matched that user input
      const matchedServices = totalServices.filter((service) => {
        return service.node.data.title.text
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

      // final service object result that will display
      const serviceResult = matchedServices.map((service) => {
        return {
          id: service.node.id,
          title: service.node.data.title.text,
          description: service.node.data.body[0].primary.description.text,
          slug: toSlug(service.node.data.title.text),
        };
      });

      setSearchResult({
        videos: videoResult,
        blogs: blogResult,
        sectors: sectorResult,
        services: serviceResult,
      });

      setSearchedText(searchTerm);
    }
  };

  const viewVideo = (videoId) => {
    window.history.replaceState(null, null, `#${videoId}`);
    setTriggerLightboxComponent(!triggerLightboxComponent);
    // window.location.hash = videoId;
  };

  const totalResult =
    searchResult.videos.length +
    searchResult.blogs.length +
    searchResult.sectors.length +
    searchResult.services.length;

  return (
    <Layout>
      <SEO title={`You searched for ${searchedText} | TEN ALPHAS`} />
      <Wrapper>
        <h2 className="heading">
          <Arrow />
          SEARCH RESULTS
        </h2>
        <main className="main-container">
          <form className="main-container__form" onSubmit={goSearch}>
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="submit" onClick={goSearch}>
              <FaSearch />
            </div>
          </form>
          {searchedText.length >= 1 ? (
            <p className="main-container__feedback">
              Your search for{" "}
              <span className="searched-text">{searchedText}</span> produced{" "}
              {totalResult} result{totalResult === 1 ? null : "s"}.
            </p>
          ) : (
            <p className="main-container__feedback">empty...</p>
          )}
          <div className="main-container__success-result">
            {searchResult.videos.length >= 1 && (
              <div className="video-result">
                <h2 className="video-result__heading">Video</h2>
                <ul className="video-result__list">
                  {searchResult.videos.map((video) => (
                    <li className="video-item" key={video.node.id}>
                      <div
                        className="video-item__img"
                        onClick={() =>
                          viewVideo(getVimeoId(video.node.data.video_url))
                        }
                      >
                        <ReactPlayer
                          className="thumbnail"
                          url={video.node.data.video_url}
                          light={`https://vumbnail.com/${getVimeoId(
                            video.node.data.video_url
                          )}.jpg`}
                        />
                      </div>
                      <div className="video-item__texts">
                        <h2
                          className="video-title"
                          onClick={() =>
                            viewVideo(getVimeoId(video.node.data.video_url))
                          }
                        >
                          {video.node.data.title.text}
                        </h2>
                        <p className="video-date">
                          {video.node.data.date
                            ? formatDate(video.node.data.date)
                            : formatDate(video.node.first_publication_date)}
                        </p>
                        <p
                          className="video-link"
                          onClick={() =>
                            viewVideo(getVimeoId(video.node.data.video_url))
                          }
                        >
                          View
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {searchResult.blogs.length >= 1 && (
              <div className="blog-result">
                <h2 className="blog-result__heading">Article</h2>
                <ul className="blog-result__list">
                  {searchResult.blogs.map((blog) => (
                    <li className="blog-item" key={blog.node.id}>
                      <h2
                        className="blog-title"
                        onClick={() =>
                          navigate(`/news/${toSlug(blog.node.data.title.text)}`)
                        }
                      >
                        {blog.node.data.title.text}
                      </h2>
                      <p className="blog-date">
                        {blog.node.data.edit_published_date
                          ? formatDate(blog.node.data.edit_published_date)
                          : formatDate(blog.node.first_publication_date)}
                      </p>
                      <p className="blog-description">
                        {blog.node.data.featured_description}
                      </p>
                      <Link
                        className="blog-link"
                        to={`/news/${toSlug(blog.node.data.title.text)}`}
                      >
                        Read More
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {searchResult.services.length >= 1 && (
              <div className="service-result">
                <h2 className="service-result__heading">Service</h2>
                <ul className="service-result__list">
                  {searchResult.services.map((service) => (
                    <li className="service-item" key={service.id}>
                      <h2
                        className="service-title"
                        onClick={() => navigate(`/${service.slug}`)}
                      >
                        {service.title}
                      </h2>

                      <p className="service-description">
                        {reduceWords(service.description)}
                      </p>
                      <Link className="service-link" to={`/${service.slug}`}>
                        Read More
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {searchResult.sectors.length >= 1 && (
              <div className="sector-result">
                <h2 className="sector-result__heading">Sector</h2>
                <ul className="sector-result__list">
                  {searchResult.sectors.map((sector) => (
                    <li className="sector-item" key={sector.node.id}>
                      <h2
                        className="sector-title"
                        onClick={() =>
                          navigate(`/${toSlug(sector.node.data.title.text)}`)
                        }
                      >
                        {sector.node.data.title.text}
                      </h2>

                      <p className="sector-description">
                        {reduceWords(
                          sector.node.data.body[0].primary.description.text
                        )}
                      </p>
                      <Link
                        className="sector-link"
                        to={`/${toSlug(sector.node.data.title.text)}`}
                      >
                        Read More
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {!totalResult && (
            <div className="main-container__no-result">
              <p className="error-feedback">Sorry, no results found.</p>
              <Link className="home-link" to="/">
                Back to Home Page
              </Link>
            </div>
          )}
        </main>
        <VideoModal triggerLightboxComponent={triggerLightboxComponent} />
      </Wrapper>
    </Layout>
  );
};

export default SearchPage;

export const dataQuery = graphql`
  {
    videos: allPrismicProject {
      edges {
        node {
          id
          first_publication_date
          data {
            title {
              text
            }
            date
            video_url
          }
        }
      }
    }
    blogs: allPrismicBlog {
      edges {
        node {
          id
          first_publication_date
          data {
            title {
              text
            }
            edit_published_date
            featured_description
          }
        }
      }
    }
    sectors: allPrismicSector {
      edges {
        node {
          id
          first_publication_date
          data {
            title {
              text
            }
            body {
              ... on PrismicSectorDataBodyContent {
                primary {
                  description {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    services: allPrismicService {
      edges {
        node {
          id
          first_publication_date
          data {
            title {
              text
            }
            body {
              ... on PrismicServiceDataBodyContent {
                primary {
                  description {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    infographics: prismicInfographicsPage {
      id
      data {
        page {
          text
        }
        body {
          ... on PrismicInfographicsPageDataBodyContent {
            primary {
              description {
                text
              }
            }
          }
        }
      }
    }
    photography: prismicPhotographyPag {
      id
      data {
        page {
          text
        }
        body {
          ... on PrismicPhotographyPagDataBodyContent {
            primary {
              description {
                text
              }
            }
          }
        }
      }
    }
    design: prismicDesignPage {
      id
      data {
        page {
          text
        }
        body {
          ... on PrismicDesignPageDataBodyContent {
            primary {
              description {
                text
              }
            }
          }
        }
      }
    }
  }
`;
